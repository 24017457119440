<template>

    <span v-if="showDesc === true && value === 'PUBLIC'">
        <Popper class="popperDark" arrow hover content="Anyone with the link">
            <span class="badge bg-grey isTooltipsLabel" :class="class">PUBLIC</span>
        </Popper>
    </span>
    <span v-if="showDesc === true && value === 'PRIVATE'">
        <Popper class="popperDark" arrow hover content="Restricted: Only people added can access">
            <span class="badge bg-grey isTooltipsLabel" :class="class">PRIVATE</span>
        </Popper>
    </span>
    <span v-if="showDesc === true && value === 'CONFIDENTIAL'">
        <Popper class="popperDark" arrow hover content="View document name and timestamp only">
            <span class="badge bg-grey isTooltipsLabel" :class="class">CONFIDENTIAL</span>
        </Popper>
    </span>

    <span v-if="showDesc !== true && value === 'PUBLIC'" class="badge bg-grey" :class="class">PUBLIC</span>
    <span v-if="showDesc !== true && value === 'PRIVATE'" class="badge bg-grey" :class="class">PRIVATE</span>
    <span v-if="showDesc !== true && value === 'CONFIDENTIAL'" class="badge bg-grey" :class="class">CONFIDENTIAL</span>
</template>

<script>
import { ref, onMounted } from 'vue'
import Popper from 'vue3-popper'

export default {
    components: { Popper },
    props: [ 'value', 'class', 'showDesc' ],
    setup (props) {

        onMounted(() => {
            // console.info('Tag::onMounted', JSON.stringify(tags.value))
        })

        return {}
    }
}
</script>

<style>

</style>